import { Flex, Text } from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';

function App() {

  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = () => {
      fetch('https://manage.radiowezel.fuki.ogg-game.pl/info')
        .then(response => response.json())
        .then(data => {
          setData(data);
          console.log(data);
        })
        .catch(error => console.error(error));
    };

    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Flex h={"100vh"} direction={"column"} justifyContent={"space-between"}>
      <Flex justifyContent={"center"} alignItems={"center"} mt={"40px"}>
        <Text fontSize={"6xl"} fontWeight={"semibold"}>Radiowęzeł FUM'u</Text>
      </Flex>
      <Flex direction={"column"} gap={"20px"} justifyContent={"center"} alignItems={"center"}>
        {/* <Flex gap={"20px"} direction={"column"} alignItems={"center"}>
          <Text fontWeight={"bold"} fontSize={"6xl"}>Radiowęzeł startuje już wkrótce!</Text>
          <Text fontWeight={"semibold"} fontSize={"4xl"}>Czekamy na wasze propozycje utworów</Text>
        </Flex> */}
        {data?.current == "Brak danych" && (
          <Flex gap={"20px"}>
            <Text fontWeight={"bold"} fontSize={"6xl"}>Aktualnie grane:</Text>
            <Text fontSize={"6xl"}>Brak</Text>
          </Flex>
        )}
        {data?.current !== "Brak danych" && (
          <>
            <Flex gap={"20px"}>
              <Text fontWeight={"bold"} fontSize={"6xl"}>Aktualnie grane:</Text>
              <Text fontSize={"6xl"}>{data?.current?.track?.name ?? "Brak danych"}</Text>
            </Flex>
            <Flex gap={"20px"}>
              <Text fontWeight={"bold"} fontSize={"4xl"}>Następny utwór:</Text>
              <Text fontSize={"4xl"}>{data?.next?.track?.name ?? "Brak danych"}</Text>
            </Flex>
          </>
        )}
      </Flex>
      <Flex justifyContent={'space-between'} m={"30px"} alignItems={"end"} gap={"20px"}>
        <Flex direction={'column'} mb={'20px'}>
          <Text fontSize={"2xl"} fontWeight={"medium"}>Radiowęzeł napędzany przez</Text>
          <Text fontSize={"2xl"} fontWeight={"medium"}>FUKI - Fumowskie Kółko Informatyczne</Text>
        </Flex>
        <Flex gap={"20px"} alignItems={"end"}>
          <Flex alignItems={"end"} direction={"column"} mb={"20px"}>
            <Text fontSize={"3xl"} fontWeight={"medium"}>Formularz propozycji utworów</Text>
            <Text fontSize={"2xl"} fontWeight={"medium"}>radiowezel.fuki.ogg-game.pl</Text>
          </Flex>
          <QRCodeSVG value="https://radiowezel.fuki.ogg-game.pl" includeMargin={true} style={{ borderRadius: "10px" }} size={512} />
        </Flex>
      </Flex>

    </Flex>
  );
}

export default App;
